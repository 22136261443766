import { DialogWrapper } from "components/Layout20/DialogWrapper";
import ButtonSubmit from "components/MyComponents/ButtonSubmit";
import CustomDate from "components/MyComponents/CustomDate";
import CustomSelect, { SELECT_SINGLE_DEFAULT_PLACEHOLDER } from "components/MyComponents/CustomSelect";
import CustomText from "components/MyComponents/CustomText";
import HorizontalDivider from "components/MyComponents/HorizontalDivider";
import { Field, Form, Formik, FormikValues } from "formik";
import { YUP_VALIDATION } from "helpers/YupHelper";
import { translateOptions } from "helpers/text_helper";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "reactstrap";
import { PROFILE_ACT } from "./store/actionsProfile";
import { IProfile } from "./store/reducerProfile";

const PROFILE_VALIDATOR = YUP_VALIDATION.YUP_OBJ.shape({
  firstName: YUP_VALIDATION.YUP_STRING_REQ,
  lastName: YUP_VALIDATION.YUP_STRING_REQ,
  cityId: YUP_VALIDATION.YUP_STRING_REQ_SELECT,
  languageId: YUP_VALIDATION.YUP_STRING_REQ_SELECT,
  birthDate: YUP_VALIDATION.YUP_BIRTH_DATE,
});


const ProfilePage_02_ProfiletData = () => {
  const dispatch = useDispatch();
  const profileStore = useSelector((state: any) => state.ProfileStore);
  const dictionaries = useSelector((state: any) => state.ConfigStore.config.dictionaries);

  const { t, i18n } = useTranslation();
  const languadeDomainTr = useMemo(() => translateOptions(dictionaries.languageDomain, t), [dictionaries.languageDomain, i18n.language]);

  const onSubmit = (values: FormikValues) => {
    dispatch(PROFILE_ACT.setProfile(values as IProfile));
  };

  return (
    <DialogWrapper title="Profile.Data.PersonalDataDivider" dialogVisibilityKey="profileData">
      <Formik
        initialValues={profileStore.profile}
        validationSchema={PROFILE_VALIDATOR}
        onSubmit={onSubmit}
      >
        <Form>
          <Row>
            <Col xs={12} md={4}>
              <Field name="firstName" label="Profile.Data.FirstName" component={CustomText} isDisabled={true} />
            </Col>
            <Col xs={12} md={4}>
              <Field name="lastName" label="Profile.Data.LastName" component={CustomText} isDisabled={true} />
            </Col>
            <Col xs={12} md={4}>
              <Field name="cityId" label="Profile.Data.City"
                textAfter="Profile.Data.City.AdditionalComment"
                placeholder={SELECT_SINGLE_DEFAULT_PLACEHOLDER} isMulti={false}
                options={dictionaries.cityDomain} className="custom-select"
                component={CustomSelect}
                disabled={profileStore.areChangesInProgress} />
            </Col>

          

            <Col xs={12} md={4}>
              <Field name="languageId" label="Profile.Data.Language"
                textAfter="Profile.Data.Language.AdditionalComment"
                placeholder={SELECT_SINGLE_DEFAULT_PLACEHOLDER} isMulti={false}
                options={languadeDomainTr}
                className="custom-select" component={CustomSelect}
                disabled={profileStore.areChangesInProgress}
              />
            </Col>
            <Col xs={12} md={2}>
              <Field name="birthDate" label="Profile.Data.birthDate" component={CustomDate} isDisabled={true} />
            </Col>
            <Col xs={12} md={2}>
              <Field name="contractorId" forcedId="SPIRIT24-ID-INPUT" label="Profile.Data.contractorId" component={CustomText} isDisabled={true} />
            </Col>
            <Col xs={12} md={4} className="inline-buttons input-box">
              <ButtonSubmit
                id="button-profile-data-change"
                isLoading={profileStore.areChangesInProgress}
                label="Profile.Data.Submit"
                iconFileSrc="/img/figma/UserId.svg" />
            </Col>
          </Row>
        </Form>
      </Formik>
    </DialogWrapper>
  );
};

export default ProfilePage_02_ProfiletData; 