import Footer20Unauth from "components/Layout20/Footer20Unauth"
import { ReactElement } from "react"
import Unauth20WrapperLanguageChangeMenu from "./Unauth20WrapperLanguageChangeMenu"
import i18n from "i18n"
import { useTranslation } from "react-i18next"

interface Unauth20WrapperProps {
    children: ReactElement
}

export const Unauth20Wrapper = ({ children }: Unauth20WrapperProps) => {
    const lang = i18n.language
    const { t } = useTranslation();

    return (
        <>
            <section className="form-sec">
                <div className="container with-bg pt-xl-0">
                    <div className="main-logo desktop-hide">
                        <div className="logo-panel-mobile2024">                              
                            <div className="logo-mobile2024-img-container">
                                <img className="logo-mobile2024"  src={"/img/side-img-mobile.png"} alt="Spirit 24"/>                                
                                <div className="logo-mobile2024-text">
                                    <h5>{t('LoginPage.Logo.Header')}</h5>                            
                                    <h6>{t('LoginPage.Logo.SubHeaderLine1')}</h6>
                                    <h6>{t('LoginPage.Logo.SubHeaderLine2')}</h6>
                                </div>                        
                            </div>                            
                            <Unauth20WrapperLanguageChangeMenu alignment="horizontal-mobile" />                            
                        </div>
                    </div>
                    <div className="row items-center h-100">
                        <div className="col-md-6 h-100">
                            <div className="img-100 mobile-hide h-100">
                               <img src={"/img/side-img_g_"+lang+".jpg"} className="login-image" />                                
                            </div>
                        </div>
                        <div className="col-md-6 h-100 pt-1 pt-md-0" >
                            <div className="mobile-hide">
                                <Unauth20WrapperLanguageChangeMenu alignment="horizontal" />
                            </div>
                            <div className="unauth-panel">
                                {children}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
          <Footer20Unauth />
        </>
    )
}