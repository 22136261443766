import { getCompanyContextLong, getCompanyHrefByContext } from "helpers/companyContextHelper";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ROUTES } from "routes";
import AppStoreButtons from "./AppStoreButtons";
// import useScript from "hooks/useScript";
const Footer20Unauth = () => {
    const companyByContext = getCompanyContextLong();
    const companyName = useSelector((state: any) => state.LoginState.companyName);
    const history = useHistory();
    const { t } = useTranslation();
    //useScript('chatGPTConfigUnAuth.js', false);
    //useScript('https://app.gpt-trainer.com/widget-asset.min.js', true);

    return (
        <section className="form-sec pt-0">
            <div className="container with-bg py-2">
                <div className="row">
                    <div className="col-xs-12 col-md-3 py-1">
                        <AppStoreButtons align="text-start" />
                    </div>
                    <div className="col-xs-6 col-md-8 py-1">
                        <div className="btn-links">
                            <a href="#" onClick={() => history.push(ROUTES.ROUTE_PRIVACY_POLICY)}><i className="fa-duotone fa-file-shield fa-lg px-1"></i>{t('footer.privacy_policy_and_cookies')}</a>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-6 col-md-3">
                        <div className="btn-links">
                            <a href="#" onClick={() => history.push(ROUTES.ROUTE_CONTACT_FORM_UNLOGGED)}><i className="fa-duotone fa-envelope fa-lg px-1"></i> {t('ContactForm.NAV')}</a>
                        </div>
                    </div>
                    <div className="col">
                        <div className="btn-links-left">                            
                            <a href={getCompanyHrefByContext()} target="_blank" rel="noreferrer">
                                <i className="fa-duotone fa-copyright fa-lg px-1"></i>
                                <span>{new Date().getFullYear()}&nbsp;{companyName ? companyName : companyByContext}</span>
                            </a>
                            <a href="#"><i onClick={() => { window.location.reload(); }} className="fa-duotone fa-screwdriver-wrench fa-lg px-1"></i>DX</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Footer20Unauth
